import React, {useEffect, useState} from 'react';
import {Avatar, AvatarGroup, Button, Typography} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import logo from '../../assets/logo.png';
import {LanguageSwitcher} from "./languageSwitcher";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../utils/redux/store";
import {AUTHORITIES, hasAnyAuthority} from "../../AppRoutes";
import Logout from "../auth/logout";


export const Sidebar = () => {
    // const [SidebarToggle, setSidebarToggle] = useState(window.innerWidth > 1024 ? false : true);
    const {t} = useTranslation();
    const isAdmin = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ADMIN]));
    const isUser = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.USER]));
    const isOrganization = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.ORGANIZATION]));
    const isInvitee = useAppSelector(state => hasAnyAuthority(state.auth.entity.role,
        [AUTHORITIES.INVITEE]));
    const {organizationIcon} = useAppSelector(state => state.auth.entity);
    const [currentPath, setCurrentPath] = useState(window.location.pathname.replace('/', ''));
    const [trigger, setTrigger] = useState(false);
    const location = useLocation();
    const [showLogoutModal, setShowLogoutModal] = useState(false)
    useEffect(() => {
        setCurrentPath(window.location.pathname.replace('/', ''));
    }, [trigger, location.pathname]);
    return (
        <div
            className='col-3 col-lg-2 col-xl-2 p-2  vh-100 position-fixed d-none d-md-none d-lg-none d-xl-grid bg-white'
            id="sidebar"
        >
            {showLogoutModal && <Logout setShowLogoutModal={setShowLogoutModal}/>}
            <div className="p-2 h-100">
                <div className="p-0 h-100">
                    <div className="col-12 mt-2 p-1 text-center-w text-green fw-bolder fs-3">
                        Ahura Ed Tools
                    </div>
                    {organizationIcon !== null &&
                        <div className="col-12 mt-2 p-1 text-center-w text-green fw-bolder fs-3">
                            <img className="col-5" src={organizationIcon} alt="orglogo"/>
                        </div>
                    }
                    <div className="col-11 mt-2 p-1 text-center position-absolute bottom-2">
                        <Button
                            variant="text"
                            className="col-12 border-0 h-50 hover-bg-red"
                            color='primary'
                            onClick={() => setShowLogoutModal(true)}
                        >
                            {t('Wallet.Logout')}
                        </Button>
                    </div>
                    {isAdmin &&
                        <React.Fragment>
                            <div className="text-center mt-5">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/admin/mostToolsUsed'
                                        variant={currentPath === "admin/mostToolsUsed" ? "contained" : "text"}
                                        className={currentPath === "admin/mostToolsUsed" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}
                                        color='primary'>
                                    {t('admin.mostToolsUsed')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/admin/CouponManagement'
                                        color='primary'
                                        variant={currentPath === "admin/CouponManagement" ? "contained" : "text"}
                                        className={currentPath === "admin/CouponManagement" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}
                                >
                                    {t('admin.CouponManagement')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/admin/SubscriptionPlanManagement'
                                        color='primary'
                                        variant={currentPath === "admin/SubscriptionPlanManagement" ? "contained" : "text"}
                                        className={currentPath === "admin/SubscriptionPlanManagement" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}>
                                    {t('admin.SubscriptionPlanManagement')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/AdminSetting' color='primary'
                                        variant={currentPath === "AdminSetting" ? "contained" : "text"}
                                        className={currentPath === "AdminSetting" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}>
                                    {t('Wallet.MyAccount')}
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                    {isUser &&
                        <React.Fragment>
                            <div className="text-center mt-5">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/tools'
                                        color='primary'
                                        variant={currentPath === "tools" ? "contained" : "text"}
                                        className={currentPath === "tools" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}
                                >
                                    {t('Dashboard')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/favorites'
                                        color='primary'
                                        variant={currentPath === "favorites" ? "contained" : "text"}
                                        className={currentPath === "favorites" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}
                                >
                                    {t('User Favorites')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/setting'
                                        color='primary'
                                        variant={currentPath === "setting" ? "contained" : "text"}
                                        className={currentPath === "setting" ? "col-12 border-0 h-50 menuActive" : "col-12 border-0 h-50"}
                                >
                                    {t('Wallet.MyAccount')}
                                </Button>
                            </div>

                        </React.Fragment>
                    }
                    {isOrganization &&
                        <div className="mt-5">
                            <div className="text-center">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/setting' variant="text" color='primary'
                                        className="col-12 border-0">
                                    {t('Wallet.OrganizationSetting')}
                                </Button>
                            </div>
                            <div className="text-center mt-2">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/inviteTeachers' variant="text" color='primary'
                                        className="col-12 border-0">
                                    {t('organization.InviteTeachers')}
                                </Button>
                            </div>
                        </div>
                    }
                    {isInvitee &&
                        <div className={organizationIcon != null ? "" : "mt-5"}>
                            <div className="text-center">
                                {/* @ts-ignore*/}
                                <Button LinkComponent={Link} to='/inviteeSetting' variant="text" color='primary'
                                        className="col-12 border-0">
                                    {t('Invitee.Setting')}
                                </Button>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
