// Create an instance of axios with a base URL
import axios from "axios";
import {getToken} from "../localstorage/localStorage";

let SERVER_API_URL;
if (process.env.NODE_ENV !== 'production') {
    // SERVER_API_URL = 'http://127.0.0.1:8000/';
    SERVER_API_URL = 'https://toolsapi.ahura.ai/';
} else {
    SERVER_API_URL = 'https://toolsapi.ahura.ai/';
    // SERVER_API_URL = 'http://127.0.0.1:8000/';
    // SERVER_API_URL = 'http://34.121.5.143:8000/';
}

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
    const onRequestSuccess = (config: any) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };
    const onResponseSuccess = (response: any) => response;
    const onResponseError = (err: { status: any; response: { status: any; }; }) => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 403 || status === 401) {
            onUnauthenticated();
        }
        return Promise.reject(err);
    };
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

