// SubscriptionPlan.tsx
import React from 'react';
import {Button, Card, CardContent, Typography, Box, List, ListItem, ListItemIcon, Divider} from '@mui/material';
import {ISubscriptionPlan} from '../../../utils/redux/model/subscriptionPlan.model';
import {styled} from '@mui/material/styles';
import {MdCheck} from "react-icons/md";

interface SubscriptionPlanProps {
    plan: ISubscriptionPlan;
    selectedPlan: ISubscriptionPlan;
    onHandleSetSelectedPlan: (plan: ISubscriptionPlan) => void;
}

// Styled card component for a more visually appealing effect
const StyledCard = styled(Card)(({theme}) => ({
    borderRadius: 16,
    boxShadow: theme.shadows[5],
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[10],
    },
}));

// Styled button with gradient background for a more premium feel
const GradientButton = styled(Button)(({theme}) => ({
    background: '#3cb0c8',
    color: theme.palette.common.white,
    '&:hover': {
        background: '#2c8ca1',
    },
}));
const PriceText = styled(Typography)(({theme}) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#3cb0c8',
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '20px'
}));
const SubscriptionPlanContainer: React.FC<SubscriptionPlanProps> = ({plan, onHandleSetSelectedPlan, selectedPlan}) => {
    const isSelected = selectedPlan.id === plan.id || plan.name?.toLowerCase() === "free";

    // Split description into an array by \n and check if there's any content
    const descriptionList = plan.description ? plan.description.split('\n') : [];

    return (
        <div className="col-4">
            <StyledCard className="col-12 h-100 position-relative">
                <CardContent className="text-center h-100">
                    <Typography variant="h4" component="div" gutterBottom>
                        {plan.name}
                    </Typography>
                    <PriceText variant="h5" color="primary" gutterBottom>
                        {plan.price === 0 ? '$0.00' : `$${(plan.price?.toFixed(2) || '0.00')}`}
                    </PriceText>
                    {`${plan.duration_days! > 100000 ? "Unlimited" : `${plan.duration_days} days`}`}
                    {descriptionList.length > 0 ? (
                        <List className="px-5 mb-6">
                            {descriptionList.map((item, index) => (
                                <>
                                    <ListItem
                                        key={index}
                                        sx={{
                                            transition: 'all 0.3s ease',
                                            '&:hover': {
                                                transform: 'translateX(5px)',
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <MdCheck/>
                                        </ListItemIcon>
                                        <Typography variant="body1" color="text.primary">
                                            {item}
                                        </Typography>
                                    </ListItem>
                                    {index < descriptionList.length - 1 && <Divider variant="middle"/>}
                                </>
                            ))}
                        </List>
                    ) : (
                        <Typography variant="body1" color="text.secondary">
                            No description available
                        </Typography>
                    )}


                    <div className="col-11 position-absolute bottom-5 ">
                        {plan.name?.toLowerCase() === "free" ?
                            <GradientButton
                                size="large"
                                variant="contained"
                                disabled
                                onClick={() => onHandleSetSelectedPlan(plan)}
                            >
                                Current Plan
                            </GradientButton>
                            :isSelected ? (
                            <GradientButton
                                size="large"
                                variant="contained"
                                sx={{opacity: 0.7}}
                            >
                                {plan.name?.toLowerCase() === "free" ? "Current Plan" : "Selected"}
                            </GradientButton>
                        ) : (
                            <GradientButton
                                size="large"
                                variant="contained"
                                onClick={() => onHandleSetSelectedPlan(plan)}
                            >
                                Subscribe
                            </GradientButton>
                        )}
                    </div>
                </CardContent>
            </StyledCard>
        </div>
    );
};

export default SubscriptionPlanContainer;
