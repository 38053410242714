import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CircularProgress, Button, Modal, Box, TextField, MenuItem, TablePagination, FormControlLabel, Checkbox, Tooltip
} from '@mui/material';
import dayjs from 'dayjs';
import {createCouponAPI, getEntities} from "../../utils/redux/store/discountSlice";
import {useAppDispatch, useAppSelector} from "../../utils/redux/store";
import {IDiscount} from "../../utils/redux/model/discount.model";
import {IoInformationCircle} from "react-icons/io5";

const CouponManagement = () => {
    const dispatch = useAppDispatch();
    const coupons: IDiscount[] = useAppSelector(state => state.discount.entities);
    const loading = useAppSelector(state => state.discount.loading);

    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0); // Page number
    const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page

    const [formData, setFormData] = useState<IDiscount>({
        code: '',
        discount_type: '',
        discount_value: 0,
        max_reduction_amount: 0,
        max_usage_count: 0,
        expiration_date: 0,
        user_id: 0,
        is_active: false,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        // Convert expiration_date to Unix timestamp
        const unixExpirationDate = dayjs(formData.expiration_date).unix();

        const submissionData = {
            ...formData,
            expiration_date: unixExpirationDate,
        };

        try {
            dispatch(createCouponAPI(submissionData)).finally(() => {
                fetchCoupons();
                handleClose();
            })
            // Refresh the coupon list after successful creation

        } catch (error) {
            console.error("Failed to create coupon", error);
        }
    };

    const fetchCoupons = () => {
        dispatch(getEntities({limit: rowsPerPage, offset: page * rowsPerPage}));
    };

    useEffect(() => {
        fetchCoupons();
    }, [page, rowsPerPage]);

    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Coupon Management
            </Typography>

            <Button variant="contained" color="primary" onClick={handleOpen}>
                Create New Coupon
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography className="row col-12 mx-0" variant="h6" component="h2" gutterBottom>
                        <div className="col-6 px-0">Create New Coupon</div>
                        <Tooltip title="Coupon Unique Code"
                                 children={<div className="col-6 text-end px-0 "><IoInformationCircle size={22}/>
                                 </div>}/>
                    </Typography>

                    <TextField
                        fullWidth
                        label="Coupon Code"
                        name="code"
                        value={formData.code}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <Tooltip
                        title="Pecentage: final price will be Price - price * Pecentage. Fixed: final price will be price - fixed value ">
                        <TextField
                            fullWidth
                            select
                            label="Discount Type"
                            name="discount_type"
                            value={formData.discount_type}
                            onChange={handleChange}
                            margin="normal"
                            required
                        >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="amount">Amount</MenuItem>
                        </TextField>
                    </Tooltip>
                    <Tooltip title="The discount value that is in percentage or fixed $">
                        <TextField
                            fullWidth
                            label="Discount Value"
                            name="discount_value"
                            value={formData.discount_value}
                            onChange={handleChange}
                            margin="normal"
                            required
                        />
                    </Tooltip>
                    <Tooltip title="it can be zero. it means you don't want to limit the discount value on percentage type .
                                    for example : you created a coupon with a discount value of 50 and discount type is percentage. users can get a full 50% off.
                                    but if you set a limit  by max reduction amount of $10 user can get 50% off with max reduction of $10. example
                                    number 1 - $100 = 50% = max reduction 0 => $50 example
                                    number 2 - $100 = 50% = max reduction 10 => $90">
                        <TextField
                            fullWidth
                            label="Max reduction amount”"
                            name="max_reduction_amount"
                            value={formData.max_reduction_amount}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Tooltip>
                    <Tooltip
                        title="if set to 0 then this coupon can be used just once. if setting more than 0, the Coupon can be used as Max Usage Count value">
                        <TextField
                            fullWidth
                            label="Max Usage Count"
                            name="max_usage_count"
                            value={formData.max_usage_count}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Tooltip>
                    <Tooltip title="if not set then coupon has no expire date limit">
                        <TextField
                            fullWidth
                            label="Expiration Date"
                            name="expiration_date"
                            type="date"
                            value={formData.expiration_date}
                            onChange={handleChange}
                            margin="normal"
                            InputLabelProps={{shrink: true}}
                            required
                        />
                    </Tooltip>
                    <Tooltip title="if set to 0 then coupon is not tied to any user and all user can use this coupon">
                    <TextField
                        fullWidth
                        label="User ID"
                        name="user_id"
                        value={formData.user_id}
                        onChange={handleChange}
                        margin="normal"
                    />
                    </Tooltip>

                    <Tooltip title="Activation status of a Coupon">
                    <FormControlLabel
                        className="col-12"
                        control={
                            <Checkbox
                                checked={formData.is_active!}
                                onChange={handleChange}
                                name="is_active"
                            />
                        }
                        label="Is Active"
                    />
                    </Tooltip>
                    <Button type="submit" variant="contained" color="primary" sx={{mt: 2}}>
                        Create Coupon
                    </Button>
                </Box>
            </Modal>

            {loading ? (
                <CircularProgress sx={{mt: 4}}/>
            ) : (
                <TableContainer component={Paper} sx={{mt: 4}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Max Reduction</TableCell>
                                <TableCell>Usage Count</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Expiration Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {coupons.map((coupon) => (
                                <TableRow key={coupon.id}>
                                    <TableCell>{coupon.code}</TableCell>
                                    <TableCell>{coupon.discount_type}</TableCell>
                                    <TableCell>{coupon.discount_value}</TableCell>
                                    <TableCell>{coupon.max_reduction_amount}</TableCell>
                                    <TableCell>{coupon.max_usage_count}</TableCell>
                                    <TableCell>{coupon.is_active ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{dayjs.unix(coupon.expiration_date!).format('YYYY-MM-DD')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={100} // This should be replaced with the total number of records from your backend
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            )}
        </Container>
    );
};

export default CouponManagement;
